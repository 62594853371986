/* eslint-disable @typescript-eslint/explicit-function-return-type, id-length */
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { InsightsUserOnlyGuard } from '@core/guards/InsightsUserOnly.guard';

const routes: Routes = [
  {
    path: 'pricing',
    loadChildren: () => import('../pricing/pricing.module')
      .then((module) => module.PricingModule),
    data: {
      title: 'Renta Solutions MGT - Pricing Simulator'
    }
  },
  {
    path: 'company',
    loadChildren: () => import('../company/company.module')
      .then((module) => module.CompanyModule),
    data: {
      title: 'Renta Solutions MGT - Company'
    }
  },
  {
    path: 'users',
    loadChildren: () => import('../users/users.module')
      .then((module) => module.UsersModule),
    canActivate: [InsightsUserOnlyGuard],
    data: {
      title: 'Renta Solutions MGT - Users'
    }
  },
  {
    path: 'portal',
    loadChildren: () => import('../my-profile-and-settings/my-profile-and-settings-section.module')
      .then((module) => module.MyProfileAndSettingsSectionModule),
    data: {
      title: 'Renta Solutions MGT - My Profile'
    }
  },
  {
    path: 'register-company-manager',
    loadChildren: () => import('../register-company-manager/register-company-manager.module')
      .then((module) => module.RegisterCompanyManagerModule),
    data: {
      title: 'Renta Solutions MGT - Register Company Manager'
    }
  },
  {
    path: 'review-company-manager/:id',
    loadChildren: () => import('../review-company-manager/review-company-manager.module')
      .then((module) => module.ReviewCompanyManagerModule),
    data: {
      title: 'Renta Solutions MGT - Review Company Manager',
      hideTopBar: true,
    }
  },
  {
    path: 'insights',
    children: [
      {
        path: ':groupName/:groupId',
        pathMatch: 'full',
        loadComponent: () => import('../insights-bi-reports/table-list/table-list.component').then((component) => component.TableListComponent),
        data: {
          title: 'Renta Solutions MGT - Insights List'
        }
      },
      {
        path: ':groupName/:groupId/report/:reportId',
        loadComponent: () => import('../insights-bi-reports/report/report.component').then((component) => component.ReportComponent),
        data: {
          title: 'Renta Solutions MGT - Insights Report'
        }
      }
    ]
  },
  {
    path: '**',
    redirectTo: 'users'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
