import { Component, Input } from '@angular/core';
import { AccountWithoutManager } from 'src/app/register-company-manager/models/account-without-manager';
import { AccountWithoutManagerGuid } from 'src/app/review-company-manager/models/account-with-manager';

@Component({
  selector: 'mgt-company-card[company]',
  templateUrl: './company-card.component.html',
  styleUrls: ['./company-card.component.scss']
})
export class CompanyCardComponent {
  @Input() public company!: AccountWithoutManager | AccountWithoutManagerGuid;

  constructor() {
  }
}
