import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UserState } from '@users/state/users.reducer';
import { myUsersDetailsFeatureKey } from './my-profile-and-settings.reducer';

export const selectMyUserState = createFeatureSelector<UserState>(
  myUsersDetailsFeatureKey
);

export const myUserDetails = createSelector(
  selectMyUserState,
  (state) => state
);
