import { createAction, props } from '@ngrx/store';
import { UserDetails } from '@shared/models/user-details';
import { UserProfile } from '@shared/models/user-profile';

export const storeCurrentUserDetails = createAction(
  '[userDetails] Set the current user details',
  props<{ userDetails: UserDetails }>()
);

export const updateCurrentUserProfile = createAction(
  '[userDetails] update the current user profile details',
  props<{ userProfile: UserProfile }>()
);

export const clearCurrentUserDetails = createAction(
  '[userDetails] Clear the current user details from the store'
);
