import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AppService } from '../../app/services/app/app.service';
import { map, switchMap } from 'rxjs/operators';
import { finalize, iif, of } from 'rxjs';
import { RsLoaderService } from '@lib/rs-ngx';


export const InsightsUserOnlyGuard: CanActivateFn = () => {
  const appService = inject(AppService);
  const router = inject(Router);
  const rsLoaderService = inject(RsLoaderService);

  rsLoaderService.show();

  return appService
    .getUserAccesses()
    .pipe(
      map((userAccesses) => Object.entries(userAccesses).filter(([_key, value]) => value).map(([key]) => key)),
      switchMap((accesses) => iif(
        () => !(accesses.length === 1 && accesses[0] === 'insights'),
        of(true),
        appService.getInsightsMenu().pipe(
          switchMap(
            (insightsSubMenu) => of(
              router.parseUrl(`/insights/${insightsSubMenu[0].name}/${insightsSubMenu[0].id}`)
            )
          )
        )
      )),
      finalize(() => rsLoaderService.hide())
    );
}


