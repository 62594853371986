import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { environment } from './environments/environment';
import { RsSentry } from '@lib/utils/rs-senty-init';
import { AppModule } from './app/app/app.module';
import * as Sentry from '@sentry/angular-ivy';

new RsSentry({
  dsn: 'https://e591189831e1495e83617c7085d131be@o268277.ingest.sentry.io/1877372',
  enabled: environment.enableSentryMonitoring,
  environment: environment.environment,
  sentryReleaseName: environment.sentryReleaseName
}).sentryInit();

Sentry.configureScope((scope): void => {
  scope.setTag('app.name', 'MGT');
  scope.setTag('app.environment', environment.environment as string);
});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
