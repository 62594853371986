import { createAction, props } from '@ngrx/store';
import { UserDetails } from '@shared/models/user-details';
import { UserProfile } from '@shared/models/user-profile';

export const storeMyUserDetails = createAction(
  '[myUserDetails] Set the logged in current user details',
  props<{ myUserDetails: UserDetails }>()
);

export const updateMyUserProfile = createAction(
  '[myUserDetails] Set the logged in current user details',
  props<{ myUserProfile: UserProfile }>()
);
