import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { TranslationService, TranslationsLanguage } from '@lib/rs-ngx';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UserProfileForm } from '@shared/components/user-profile-form/models/user-profile-form';
import { Subject } from 'rxjs';
import { AccountWithManager } from '@review-company-manager/models/account-with-manager';

@Component({
  selector: 'mgt-user-profile-form',
  templateUrl: './user-profile-form.component.html',
  styleUrls: ['./user-profile-form.component.scss']
})
export class UserProfileFormComponent implements OnInit, OnDestroy {
    @Input() public isEmailDisabled: boolean = true;
    @Input() public initialValue?: AccountWithManager;

    public readonly userProfileForm: FormGroup<UserProfileForm>;
    public readonly languages: TranslationsLanguage[];

    private readonly destroyed$ = new Subject<boolean>();

    constructor(
        private readonly translationService: TranslationService
    ) {
      this.languages = this.translationService.languages;
      this.userProfileForm = new FormGroup<UserProfileForm>({
        email: new FormControl({
          value: '',
          disabled: this.isEmailDisabled
        }, {
          nonNullable: true,
          validators: [
            Validators.required,
            Validators.email
          ],
        }),
        firstName: new FormControl('', {
          nonNullable: true,
          validators: [
            Validators.required,
            Validators.maxLength(29)
          ]
        }),
        lastName: new FormControl('', {
          nonNullable: true,
          validators: [
            Validators.required,
            Validators.maxLength(30)
          ]
        }),
        language: new FormControl(null, [Validators.required]),
        jobTitle: new FormControl('', { nonNullable: true }),
        phone: new FormControl('', {
          nonNullable: true,
          validators: [
            Validators.maxLength(20),
            Validators.pattern(/^[+{0}\d(][\s0-9()/]+[0-9]$/),
          ]
        }),
        mobile: new FormControl('', {
          nonNullable: true,
          validators: [
            Validators.maxLength(20),
            Validators.pattern(/^[+{0}\d(][\s0-9()/]+[0-9]$/)
          ]
        })
      });
    }

    @Input()
    public set disabled(isDisabled: boolean) {
      if (isDisabled) {
        this.userProfileForm.disable();
      } else {
        this.userProfileForm.enable();
      }
    }

    public ngOnInit(): void {
      if (this.isEmailDisabled) {
        this.userProfileForm.controls.email.disable();
      } else {
        this.userProfileForm.controls.email.enable();
      }

      if (this.initialValue) {
        this.userProfileForm.patchValue(this.initialValue);
      }
    }

    public ngOnDestroy(): void {
      this.destroyed$.next(true);
      this.destroyed$.complete();
    }
}
