<ct-profile-card *ngIf='(profile$ | async) as profile'>
  <ct-user-global-info>

    <ct-initials-circle>
      {{ profile?.initials }}
    </ct-initials-circle>

    <ct-user-name class='mb-5px'>
      {{ profile?.firstName }} {{ profile?.lastName }}
    </ct-user-name>

    <mgt-platform-chip [platforms]='profile?.platforms'>
    </mgt-platform-chip>


  </ct-user-global-info>

  <ct-user-contact-info>

    <ct-email>
      <mat-icon class='material-icons-outlined'>email</mat-icon>
      {{ profile?.email }}
    </ct-email>

    <ct-error-message-email *ngIf="profile.recentBounce">
      <mat-icon>error_outline</mat-icon>
      {{ 'EMAIL_ERROR' | translate}}
    </ct-error-message-email>

    <ct-mobile *ngIf='profile?.mobile'>
      <mat-icon class='material-icons-outlined'>smartphone</mat-icon>
      {{ profile?.mobile }}
    </ct-mobile>

    <ct-phone *ngIf='profile?.phone'>
      <mat-icon class='material-icons-outlined'>phone</mat-icon>
      {{ profile?.phone }}
    </ct-phone>

    <ct-last-login *ngIf='!isMyUser'>
      <mat-icon class='material-icons-outlined'>login</mat-icon>
      {{ "LAST_LOGIN" | translate }}

      <ng-container *ngIf='lastLoginData; else neverCopy'>
        {{ lastLoginData | date: "shortDate":"":currentLang }}
      </ng-container>

      <ng-template #neverCopy>
        {{ "NEVER" | translate }}
      </ng-template>
    </ct-last-login>

  </ct-user-contact-info>
</ct-profile-card>