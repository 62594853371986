import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { errorHandlerProviders } from '@core/error-handlers';
import { EffectsModule } from '@ngrx/effects';
import { isMobiSkin, RsCoreModule, UserInfoCookieAppName, } from '@lib/rs-ngx';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeEn from '@angular/common/locales/en-GB';
import localeFr from '@angular/common/locales/fr';
import localeNl from '@angular/common/locales/nl';
import { environment, environmentMobi } from '@environments/environment';

// Registering some locals to be able to used it for numbers and dates
registerLocaleData(localeFr);
registerLocaleData(localeNl);
registerLocaleData(localeEn, 'en');
registerLocaleData(localeDe);

const appInitializerSuccessCallBack = (): void => {
  if (isMobiSkin) {
    Object.assign(environment, environmentMobi);
  }
};

@NgModule({ imports: [
  BrowserAnimationsModule,
  RecaptchaV3Module,
  EffectsModule.forRoot(),
  RsCoreModule.forRoot({
    appInitializerSuccessCallBack,
    appName: UserInfoCookieAppName.MGT,
    environment
  })
],
providers: [
  errorHandlerProviders,
  {
    provide: RECAPTCHA_V3_SITE_KEY,
    useValue: '6LdDHrUUAAAAAKzMz2EpUXQFj554t_g0Gu8BJetN'
  },
  provideHttpClient(withInterceptorsFromDi())
] })
export class CoreModule {
  /**
   * Ensures that the CoreModule can only be loaded once.
   *
   * https://angular.io/guide/singleton-services#prevent-reimport-of-the-greetingmodule
   *
   * @param parentModule
   */
  public constructor(@Optional() @SkipSelf() parentModule?: CoreModule) {
    if (parentModule) {
      throw new Error(
        'CoreModule is already loaded. Import it only once!'
      );
    }
  }
}
