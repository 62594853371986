import { createFeatureSelector, createSelector } from '@ngrx/store';
import { usersDetailsFeatureKey, UserState } from '@users/state/users.reducer';

export const selectUsersState = createFeatureSelector<UserState>(
  usersDetailsFeatureKey
);

export const userDetails = createSelector(
  selectUsersState,
  (state) => state
);
