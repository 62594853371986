import { createReducer, on } from '@ngrx/store';
import { clearCurrentUserDetails, storeCurrentUserDetails, updateCurrentUserProfile } from '@users/state/users.action';
import { Platform } from '@shared/models/user';
import { LanguagesShort, UUID } from '@lib/rs-ngx';

export const usersDetailsFeatureKey = 'userDetails';

export interface UserState {
  guid: UUID;
    firstName: string;
    lastName: string;
    initials: string;
    platforms: Platform[];
    email: string;
    jobTitle: string;
  language: LanguagesShort;
    mobile: string;
    phone: string;
    recentBounce: false;
}

export const initialUserState: UserState | {} = {};

export const userDetailsReducer = createReducer(
  initialUserState,
  on(storeCurrentUserDetails, (state, { userDetails }) => {
    return {
      ...state,
      ...userDetails
    };
  }),
  on(updateCurrentUserProfile, (state, { userProfile }) => {
    return {
      ...state,
      ...userProfile
    };
  }),
  on(clearCurrentUserDetails, () => {
    return initialUserState;
  })
);

