<form
  [formGroup]='userProfileForm'
  data-testid="user-profile-form"
>
  <!-- FIRST_NAME -->
  <mat-form-field rsAddClearFormFieldButton>
    <mat-label>{{ 'FIRST_NAME' | translate }}</mat-label>
    <input
      formControlName='firstName'
      matInput
      maxlength='29'
    />
    <mat-error
      *ngIf="userProfileForm.controls.firstName.hasError('required')"
      data-testid='firstname-error-required'
    >
      {{ 'ERROR_MSG.REQUIRED_FIELD' | translate }}
    </mat-error>
  </mat-form-field>

  <!-- LAST_NAME -->
  <mat-form-field rsAddClearFormFieldButton>
    <mat-label>{{ 'LAST_NAME' | translate }}</mat-label>
    <input
      formControlName='lastName'
      matInput
      maxlength='30'
    />
    <mat-error
      *ngIf="userProfileForm.controls.lastName.hasError('required')"
      data-testid='lastname-error-required'
    >
      {{ 'ERROR_MSG.REQUIRED_FIELD' | translate }}
    </mat-error>
  </mat-form-field>

  <!-- JOB_TITLE -->
  <mat-form-field rsAddClearFormFieldButton>
    <mat-label>{{ 'JOB_TITLE' | translate }}</mat-label>
    <input
      formControlName='jobTitle'
      matInput
    />
  </mat-form-field>

  <!-- EMAIL ( CAN BE DISABLED ) -->
  <mat-form-field id='mat-form-field-email'>
    <mat-label>{{ 'EMAIL' | translate }}</mat-label>
    <input
      formControlName='email'
      matInput
      type='email'
    >
    <mat-error *ngIf="userProfileForm.controls.email.hasError('impersonalEmail')">
      {{ 'IMPERSONAL_EMAIL_ERROR' | translate }}
    </mat-error>

    <mat-error *ngIf="userProfileForm.controls.email.hasError('email')">
      {{ 'FORM_ERRORS.EMAIL_VALID' | translate }}
    </mat-error>
  </mat-form-field>

  <!-- PHONE -->
  <mat-form-field rsAddClearFormFieldButton>
    <mat-label>{{ 'PHONE' | translate }}</mat-label>
    <input
      formControlName='phone'
      matInput
      maxlength='20'
    />

    <mat-error
      *ngIf="userProfileForm.controls.phone.hasError('pattern')"
      data-testid='phone-error-invalid-format'
    >
      {{ 'FORM_ERRORS.INVALID_PHONE_FORMAT' | translate }}
    </mat-error>
  </mat-form-field>

  <!-- MOBILE -->
  <mat-form-field rsAddClearFormFieldButton>
    <mat-label>{{ 'MOBILE' | translate }}</mat-label>
    <input
      formControlName='mobile'
      matInput
      maxlength='20'
    />

    <mat-error
      *ngIf="userProfileForm.controls.mobile.hasError('pattern')"
      data-testid='mobile-error-invalid-format'
    >
      {{ 'FORM_ERRORS.INVALID_PHONE_FORMAT' | translate }}
    </mat-error>
  </mat-form-field>

  <!-- LANGUAGE -->
  <mat-form-field rsAddClearFormFieldButton>

    <mat-label>{{ "LANGUAGE" | translate }}</mat-label>

    <mat-select formControlName='language'>
      <mat-option
        *ngFor='let language of languages'
        [value]='language.short'
      >
        {{ language.apiLong | translate }}
      </mat-option>
    </mat-select>

    <mat-error
      *ngIf="userProfileForm.controls.language.hasError('required')"
      data-testid='language-error-required'
    >
      {{ 'ERROR_MSG.REQUIRED_FIELD' | translate }}
    </mat-error>
  </mat-form-field>
</form>