import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { concat, iif, map, Observable, of } from 'rxjs';
import { environment } from '@environments/environment';
import { MappedUserAccesses, UserAccesses } from '@shared/models/user-accesses';
import { shareReplay, switchMap } from 'rxjs/operators';
import { InsightGroupSubMenu } from '@shared/models/insights';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  private readonly getUserAccesses$: Observable<UserAccesses> =
    this.http.get<UserAccesses>(`${environment.apiUrl}/logged-user/ui/feature-accesses`)
      .pipe(
        shareReplay({
          bufferSize: 1,
          refCount: true
        })
      );
  private readonly getInsightsMenu$ = this.http.get<InsightGroupSubMenu[]>(`${environment.apiUrl}/insights`)
    .pipe(
      shareReplay({
        bufferSize: 1,
        refCount: true
      })
    );

  constructor(private readonly http: HttpClient) {
  }

  public readonly getUserAccesses = (): Observable<UserAccesses> => {
    return this.getUserAccesses$;
  }

  public readonly getInsightsMenu = (): Observable<InsightGroupSubMenu[]> => {
    return this.getInsightsMenu$;
  }

  public readonly mappedUserAccesses = (): Observable<MappedUserAccesses> => {
    const getUserAccesses$ = this.getUserAccesses()
      .pipe(
        map((topMenuItems) => ({
          ...topMenuItems,
          insights: topMenuItems.insights ? [{ name: '', id: '' }] : [] as InsightGroupSubMenu[]
        })),
        shareReplay({ bufferSize: 1, refCount: true })
      );

    return concat(
      getUserAccesses$,
      getUserAccesses$
        .pipe(
          switchMap(
            (topMenuItems) => iif(
              () => Boolean(topMenuItems.insights.length)
              , this.getInsightsMenu()
                .pipe(
                  map((insightGroups) => ({
                    ...topMenuItems,
                    insights: insightGroups
                  }))
                )
              , of({
                ...topMenuItems,
                insights: []
              })
            )
          )
        )
    );
  }
}
