import { createReducer, on } from '@ngrx/store';
import { Platform } from '@shared/models/user';
import { storeMyUserDetails, updateMyUserProfile } from './my-profile-and-settings.action';
import { LanguagesShort, UUID } from '@lib/rs-ngx';

export const myUsersDetailsFeatureKey = 'myUserDetails';

export interface UserState {
  guid: UUID;
    firstName: string;
    lastName: string;
    initials: string;
    platforms: Platform[];
    email: string;
    jobTitle: string;
  language: LanguagesShort;
    mobile: string;
    phone: string;
}

export const initialUserState: UserState | {} = {};

export const myUserDetailsReducer = createReducer(
  initialUserState,
  on(storeMyUserDetails, (state, { myUserDetails }) => {
    return {
      ...state,
      ...myUserDetails
    };
  }),
  on(updateMyUserProfile, (state, { myUserProfile }) => {
    return {
      ...state,
      ...myUserProfile
    };
  })
);

