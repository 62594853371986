import { Component } from '@angular/core';
import { environment } from '@environments/environment';
import { Title } from '@angular/platform-browser';
import { AppService } from './services/app/app.service';
import { filter, map } from 'rxjs/operators';
import { Router, RoutesRecognized } from '@angular/router';
import { Observable, take } from 'rxjs';
import {
  RsMessagesHandlerService
} from '@rs-shared-lib/components/rs-messages-handler/services/rs-messages-handler.service';
import { MenuItem } from '@rs-shared-lib/models/top-bar-menu-items';
import { isMobiSkin } from '@rs-shared-scripts/applicationsSkinSetter';

@Component({
  selector: 'mgt-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  public readonly topBarEnvironment = environment.topBarEnvironment;
  public readonly topBarCdn = environment.topBarCdn;
  public readonly shouldShowTopBar$: Observable<boolean>;
  public readonly menuItems$: Observable<MenuItem[]>;

  public constructor(
    private readonly titleService: Title,
    private readonly appService: AppService,
    private readonly rsMessagesHandlerService: RsMessagesHandlerService,
    private readonly router: Router,
  ) {
    this.shouldShowTopBar$ = this.router.events.pipe(
      filter(event => event instanceof RoutesRecognized),
      map(event => {
        const hideTopBar = (event as RoutesRecognized).state.root.firstChild?.data.hideTopBar ?? false;
        return !hideTopBar;
      }),
      take(1)
    );

    this.menuItems$ = this.appService
      .mappedUserAccesses()
      .pipe(
        map((accesses): MenuItem[] => (
          [
            {
              url: '/users',
              id: 'users',
              label: 'USERS',
              display: accesses.users,
            },
            {
              id: 'company',
              url: '/company',
              label: 'COMPANY',
              display: accesses.company,
            },
            {
              id: 'pricing',
              url: '/pricing',
              label: 'PRICING',
              display: accesses.pricing,
            },
            {
              id: 'insights',
              label: 'INSIGHTS.INSIGHTS',
              display: Boolean(Object.keys(accesses.insights).length),
              subMenuItems: accesses.insights
                .map(
                  (group) => ({
                    id: group.name,
                    url: `/insights/${group.name}/${group.id}`,
                    label: group.name,
                    doNotTranslateLabel: true,
                  })
                )
            }
          ]
        ))
      );
  }

  public ngOnInit(): void {
    this.titleService.setTitle(isMobiSkin ? 'Mobiview' : 'MGT UI');
  }
}
