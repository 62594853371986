<ct-profile-card *ngIf='company'>
  <ct-company-global-info>
    <ct-logo-circle>
      <mat-icon svgIcon="company"></mat-icon>
    </ct-logo-circle>

    <ct-company-name
      class='mb-5px text-center'
      data-testid="profile-card-company-name"
    >
      {{ company.name }}
    </ct-company-name>

    <ct-vat-number data-testid="profile-card-vat-number">
      {{ company.vatNumber }}
    </ct-vat-number>

  </ct-company-global-info>

  <ct-company-adress-info
    class="row"
    data-testid="profile-card-company-address"
  >
    <mat-icon class="col-2">place</mat-icon>

    <div class="col-10">
      {{ company.address.street }}
      {{ company.address.houseNumber }}
      {{ company.address.postalCode }}
      {{ company.address.city}}
      {{ 'COUNTRIES.' + company.address.country | translate }}
    </div>
  </ct-company-adress-info>
</ct-profile-card>